
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import List from "@/views/branch/List.vue";
import Upsert from "@/views/branch/Upsert.vue";

import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { BranchActions, BranchGetters } from "@/store/vm/branch/enums";

interface ComponentData {
  branchIdModal: number | boolean;
}
export default defineComponent({
  name: "branch",
  props: {
    widgetClasses: String,
  },
  components: {
    List,
    Upsert,
  },
  data(): ComponentData {
    return {
      branchIdModal: false,
    };
  },
  methods: {
    onUpsert(branchId: number | boolean) {
      this.branchIdModal = branchId;
    },
    onReset() {
      this.branchIdModal = false;
    },
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Chi nhánh", ["Hệ thống"]);
      MenuComponent.reinitialization();
    });

    const onDel = (branchId) => {
      const detail = store.getters[BranchGetters.GET_BY_ID](branchId);

      Swal.fire({
        title: "Bạn muốn xóa chi nhánh?",
        text: detail.name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resDel = await actionDelete(branchId);
          console.log("resDel");
          console.log(resDel);
        }
      });
    };

    const actionDelete = async (branchId) => {
      return store.dispatch(BranchActions.DELETE, branchId).catch((e) => {
        console.log(e);
      });
    };

    return {
      onDel,
    };
  },
});
