
import { computed, defineComponent, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { BranchActions, BranchGetters } from "@/store/vm/branch/enums";
import { StepResult } from "@/store/captain/CaptainModule";

export default defineComponent({
  name: "upsert-branch",
  components: {},
  props: {
    branchId: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
  },
  emits: ["reset"],
  setup(props, { emit }) {
    const store = useStore();

    const formRef = ref<null | HTMLFormElement>(null);
    const branchUpsertModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const defaultData = {
      branchId: "",
      name: "",
      address: "",
      status: "active",
      longitude: "21.02966394144484",
      latitude: "105.80461252663332",
    };

    const formData = ref({ ...defaultData });

    const modalUpsertId = computed(() => {
      return store.state.BranchModule.modalUpsertId;
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Yêu cầu nhập tên chi nhánh",
          trigger: "change",
        },
      ],
      address: [
        {
          required: true,
          message: "Yêu cầu nhập địa chỉ",
          trigger: "change",
        },
      ],
    });

    const isCreate = computed(() => {
      return !props.branchId;
    });

    const branchIdTarget = computed(() => {
      return props.branchId;
    });

    watch(branchIdTarget, (currentValue) => {
      if (currentValue) {
        // update
        const detail = store.getters[BranchGetters.GET_BY_ID](currentValue);
        formData.value = detail;
      } else {
        reset();
      }
    });

    const reset = () => {
      formData.value = defaultData;
      emit("reset");
    };

    const actionCreate = (par) => {
      return store.dispatch(BranchActions.CREATE, par).catch((e) => {
        console.log(e);
      });
    };

    const actionUpdate = async (par) => {
      return await store.dispatch(BranchActions.UPDATE, par).catch((e) => {
        console.log(e);
      });
    };

    const submit = async () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          let stepResult: StepResult;
          if (props.branchId) {
            stepResult = await actionUpdate(formData.value);
          } else {
            stepResult = await actionCreate(formData.value);
          }

          loading.value = false;
          if (stepResult.isSuccess) {
            hideModal(branchUpsertModalRef.value);
            Swal.fire({
              position: "top-end",
              text: "Success!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
            reset();
          } else {
            Swal.fire({
              position: "top-end",
              text: "Đã xảy ra lỗi, vui lòng thử lại sau!",
              icon: "error",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        } else {
          Swal.fire({
            text: "Định dạng dữ liệu không đúng, vui lòng kiểm tra lại biểu mẫu",
            icon: "error",
            buttonsStyling: false,
            timer: 2000,
            width: 400,
            showCancelButton: false,
            showConfirmButton: false,
          });
          return false;
        }
      });
    };

    return {
      modalUpsertId,
      isCreate,
      defaultData,
      formData,
      submit,
      formRef,
      rules,
      loading,
      branchUpsertModalRef,
      branchIdTarget,
    };
  },
});
