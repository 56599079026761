import { Modal } from "bootstrap";

const hideModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }

  const myModal = Modal.getInstance(modalEl);
  myModal.hide();
};

const removeModalBackdrop = (): void => {
  if (document.querySelectorAll(".modal-backdrop.fade.show").length) {
    document.querySelectorAll(".modal-backdrop.fade.show").forEach((item) => {
      item.remove();
    });
  }
};

// ========== TAB HELPER

export const getFocusNavItem = (event) => {
  const str = event.target.outerHTML;
  const regex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/gm;
  let m;

  let focusNavItem;
  while ((m = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    m.forEach((match, groupIndex) => {
      if (groupIndex == 2) {
        focusNavItem = match;
        return false;
      }
    });
  }
  return focusNavItem;
};

export { removeModalBackdrop, hideModal };
